import { Fraction } from './Fraction';

type HeightWidth = { height: number; width: number };
interface GetClampedSizeOptions {
  size: HeightWidth;
  max?: number;
  maxWidth?: number;
  maxHeight?: number;
  roundToInteger?: boolean;
}

export const getMaxSizeFromAspectRatio = (
  aspectRatio: HeightWidth,
  max: HeightWidth
): HeightWidth => {
  const biggerWidth = aspectRatio.width >= aspectRatio.height;
  return {
    width: biggerWidth ? max.width : getWidthByRatio(aspectRatio, max.height),
    height: !biggerWidth ? max.height : getHeightByRatio(aspectRatio, max.width)
  };
};

export const getClampedSize = (options: GetClampedSizeOptions): HeightWidth => {
  const size: HeightWidth = { ...options.size };
  if (size.width >= size.height) {
    tryClampSizeByWidth(size, options.maxWidth || options.max);
    tryClampSizeByHeight(size, options.maxHeight || options.max);
  } else {
    tryClampSizeByHeight(size, options.maxHeight || options.max);
    tryClampSizeByWidth(size, options.maxWidth || options.max);
  }

  return options.roundToInteger
    ? {
        height: Math.round(size.height),
        width: Math.round(size.width)
      }
    : size;
};

const tryClampSizeByHeight = (size: HeightWidth, max: number) => {
  if (max && size.height > max) {
    size.width = getWidthByRatio(size, max);
    size.height = max;
  }
};

const tryClampSizeByWidth = (size: HeightWidth, max: number) => {
  if (max && size.width > max) {
    size.height = getHeightByRatio(size, max);
    size.width = max;
  }
};

export const getHeightByRatio = (ratio: HeightWidth, width: number) => {
  return width * (ratio.height / ratio.width);
};

export const getWidthByRatio = (ratio: HeightWidth, height: number) => {
  return height * (ratio.width / ratio.height);
};

export const ratioToHeightWidth = (ratio: number) => {
  const fraction = new Fraction(ratio);
  return {
    width: fraction.numerator,
    height: fraction.denominator
  };
};

export const aspectRatioCss = (
  { height, width }: HeightWidth,
  auto?: boolean
) => {
  const parts: string[] = [];
  if (auto) {
    parts.push('auto');
  }

  parts.push(`${width} / ${height}`);
  return parts.join(' ');
};

interface GetClampedSizeNewOptions {
  objectSize: HeightWidth;
  containerSize: HeightWidth;
  roundToInteger?: boolean;
  objectFit?: 'contain' | 'cover';
}

/**
 * Get a size clamped to the specified maximum, while maintaining aspect ratio.
 * Defaults to object-fit contain behaviour.
 * @param options
 */
export const getClampedSizeNew = ({
  objectSize,
  containerSize,
  roundToInteger,
  objectFit = 'contain'
}: GetClampedSizeNewOptions) => {
  const objectRatio = objectSize.width / objectSize.height;
  const containerRatio = containerSize.width / containerSize.height;
  const isContains = objectFit === 'contain';

  const result = { ...objectSize };

  if (
    isContains ? objectRatio > containerRatio : objectRatio < containerRatio
  ) {
    result.width = containerSize.width;
    result.height = containerSize.width / objectRatio;
  } else {
    result.width = containerSize.height * objectRatio;
    result.height = containerSize.height;
  }

  return roundToInteger
    ? {
        height: Math.round(result.height),
        width: Math.round(result.width)
      }
    : result;
};

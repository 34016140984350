import cx from 'clsx';
import { Property } from 'csstype';
import { FCC } from 'fcc';
import NextImage from 'next/image';
import { CSSProperties, MouseEvent, SyntheticEvent } from 'react';

import { HeightWidth } from '@/common/models/HeightWidth';
import { ImageDataModel } from '@/common/models/ImageDataModel';
import { aspectRatioCss } from '@/common/utils/SizeFunctions';

import classes from './Image.module.css';
import { ImageLoader } from './ImageLoader';

export interface ImageProps {
  image: ImageDataModel;
  style?: CSSProperties;
  onLoad?: (e: SyntheticEvent<HTMLImageElement>) => void;
  objectFit?: Property.ObjectFit;
  aspectRatio?: HeightWidth;
  display?: Property.Display;
  onError?: (e: SyntheticEvent<HTMLImageElement>) => void;
  onClick?: (e: MouseEvent<HTMLImageElement>) => void;
  /**
   * When true, the image will be considered high priority and preload. Lazy loading is automatically disabled for images using priority.
   *
   * You should use the priority property on any image detected as the Largest Contentful Paint (LCP) element. It may be appropriate to have multiple priority images, as different images may be the LCP element for different viewport sizes.
   *
   * Should only be used when the image is visible above the fold. Defaults to false.
   * https://nextjs.org/docs/api-reference/next/image#priority
   */
  isPriority?: boolean;
  className?: string;
  sizes?: string;
  onCalculateSize?: (size: Partial<HeightWidth>) => Partial<HeightWidth>;
  withLoader?: boolean;
  draggable?: boolean;
  alt?: string;
}

export const Image: FCC<ImageProps> = ({
  image,
  style = {},
  isPriority = false,
  onClick,
  withLoader = true,
  objectFit,
  aspectRatio,
  display,
  className,
  onCalculateSize,
  ...rest
}) => {
  const data = image?.getRenderData();
  const hasClick = !!onClick;
  const hasBlurHash = !!data?.blurDataUrl;
  const size = onCalculateSize
    ? onCalculateSize({ width: data?.width, height: data?.height })
    : data;

  const computedStyle = resolveStyle({
    hasBlurHash,
    hasClick,
    style,
    display,
    objectFit,
    aspectRatio,
    fallbackAspectRatio: data?.fallbackAspectRatio
  });

  if (!data?.imageUrl) {
    return null;
  }

  const hasWidth = !!size?.width;
  if (hasBlurHash) {
    return (
      <NextImage
        key={data.imageUrl}
        className={className}
        src={data.imageUrl}
        blurDataURL={data.blurDataUrl}
        alt={data?.imageAlt || ' '}
        placeholder="blur"
        priority={isPriority}
        loader={hasWidth && withLoader ? ImageLoader : undefined}
        style={{
          width: '100%',
          height: '100%',
          ...computedStyle
        }}
        onClick={onClick}
        {...rest}
        //Next-js image needs a height and width to be set
        height={size?.height ?? 800}
        width={size?.width ?? 800}
      />
    );
  }

  return (
    <img
      className={cx(classes.image, className)}
      loading={isPriority ? 'eager' : 'lazy'}
      src={data.imageUrl}
      alt={data?.imageAlt || ' '}
      style={computedStyle}
      onClick={onClick}
      {...rest}
    />
  );
};

interface ResolveStyleOptions {
  hasClick: boolean;
  hasBlurHash: boolean;
  style?: CSSProperties;
  objectFit?: Property.ObjectFit;
  aspectRatio?: HeightWidth;
  display?: Property.Display;
  fallbackAspectRatio?: HeightWidth;
}

const resolveStyle = ({
  hasClick,
  hasBlurHash,
  style,
  display,
  objectFit,
  aspectRatio,
  fallbackAspectRatio
}: ResolveStyleOptions): CSSProperties => {
  const hasFallbackAspectRatio = !!fallbackAspectRatio;
  return {
    objectFit:
      objectFit as any /* Property.ObjectFit contains 'revert-layer' but CSSProperties.objectFit does not */,
    display,
    height: hasBlurHash ? '100%' : undefined,
    width: hasBlurHash ? '100%' : undefined,
    cursor: hasClick ? 'pointer' : undefined,
    aspectRatio: !!aspectRatio
      ? aspectRatioCss(aspectRatio)
      : !hasBlurHash && hasFallbackAspectRatio
        ? aspectRatioCss(fallbackAspectRatio)
        : undefined,
    ...(style || {})
  };
};

import { FCC } from 'fcc';
import { CSSProperties, FC, ReactNode } from 'react';

import { BlockLoader, BlockLoaderProps } from '@/common/components/BlockLoader';
import { Button, ButtonProps } from '@/common/components/Button';
import { Box, BoxProps } from '@/common/components/Display/Box';
import { Center, CenterProps } from '@/common/components/Display/Center';
import { Stack, StackProps } from '@/common/components/Display/Stack';
import { Image } from '@/common/components/Image';
import { Text } from '@/common/components/Typography/Text';
import { Title } from '@/common/components/Typography/Title';
import { ImageDataModel } from '@/common/models/ImageDataModel';
import { UndrawImages } from '@/common/utils/UndrawImages';

import classes from './EmptyStateResult.module.css';

export interface EmptyStateResultProps extends Omit<StackProps, 'title'> {
  image?: ImageDataModel;
  imageComponent?: ReactNode;
  imageStyle?: CSSProperties;
  imageWidth?: number;
  title?: ReactNode;
  alignTitleCenter?: boolean;
  subTitle?: ReactNode;
  innerPadding?: CenterProps['p'];
  callToAction?: CallToActionProps;
  isLoading?: boolean;
  isLoadingVariant?: BlockLoaderProps['variant'];
  withImage?: boolean;
}

export const EmptyStateResult: FCC<EmptyStateResultProps> = ({
  image,
  imageComponent,
  imageStyle,
  title,
  subTitle,
  children,
  imageWidth,
  alignTitleCenter = true,
  withImage = true,
  innerPadding = '1.5rem',
  callToAction,
  isLoading,
  isLoadingVariant = 'komo',
  ...rest
}) => {
  const hasChildren = !!children;
  const hasCta = !!callToAction;
  const hasContent = hasChildren || hasCta;
  const hasImageComponent = !!imageComponent;
  return (
    <Stack justify="center" h="100%" w="100%" {...rest}>
      <Center p={innerPadding}>
        <BlockLoader variant={isLoadingVariant} isLoading={isLoading}>
          <Stack gap="0.75rem" align="center" maw={500}>
            {withImage &&
              (hasImageComponent ? (
                imageComponent
              ) : (
                <Image
                  style={resolveStyle({ imageStyle, imageWidth })}
                  image={image || UndrawImages.searching}
                />
              ))}
            {title && (
              <Title order={3} ta={!alignTitleCenter ? undefined : 'center'}>
                {title}
              </Title>
            )}
            {subTitle && (
              <Text fw={400} ta="center">
                {subTitle}
              </Text>
            )}
            {hasContent && (
              <Box mt="0.2rem">
                <OptionalCallToAction action={callToAction} />
                {children}
              </Box>
            )}
          </Stack>
        </BlockLoader>
      </Center>
    </Stack>
  );
};

const resolveStyle = ({
  imageStyle = {},
  imageWidth
}: Pick<EmptyStateResultProps, 'imageStyle' | 'imageWidth'>) => {
  const style = structuredClone(imageStyle);

  if (imageWidth) {
    style.width = imageWidth;
  }

  if (!style.width) {
    style.maxWidth = 250;
  }
  return style;
};

interface CallToActionProps
  extends Pick<
    ButtonProps,
    | 'leftSection'
    | 'variant'
    | 'disabled'
    | 'busy'
    | 'onClick'
    | 'm'
    | 'mb'
    | 'mt'
    | 'my'
    | 'mx'
    | 'size'
  > {
  icon?: ReactNode;
  label: string;
  labelHiddenFrom?: BoxProps['hiddenFrom'];
  labelVisibleFrom?: BoxProps['visibleFrom'];
}

const OptionalCallToAction: FC<{ action?: CallToActionProps }> = ({
  action
}) => {
  if (!action) {
    return null;
  }

  const {
    variant = 'filled',
    icon,
    label,
    onClick,
    labelHiddenFrom,
    labelVisibleFrom,
    ...rest
  } = action;
  return (
    <Button variant={variant} onClick={onClick} leftSection={icon} {...rest}>
      <Box
        className={classes.callToActionLabel}
        hiddenFrom={labelHiddenFrom}
        visibleFrom={labelVisibleFrom}
      >
        {label}
      </Box>
    </Button>
  );
};
